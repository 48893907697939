var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isChatbotActive)?_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-header align-items-center px-4 py-3"},[_c('div',{staticClass:"text-left flex-grow-1"}),_c('div',{staticClass:"text-center flex-grow-1"},[_c('div',{staticClass:"text-dark-75 font-weight-bold font-size-h5"},[_vm._v(" "+_vm._s(_vm.bot.title)+" ")]),_c('div',[(_vm.state === 'active')?_c('span',{staticClass:"label label-dot label-success"}):_vm._e(),(_vm.state === 'offline')?_c('span',{staticClass:"label label-dot label-danger"}):_vm._e(),_c('span',{staticClass:"font-weight-bold text-muted font-size-sm"},[_vm._v("  "+_vm._s(_vm.$t(`menu.chatbot.status.${_vm.state}`))+" ")])])]),_c('div',{staticClass:"text-right flex-grow-1"},[_c('button',{staticClass:"btn btn-clean btn-sm btn-icon btn-icon-md",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('expand-modal')}}},[_c('i',{staticClass:"fas fa-expand icon-1x"})]),_c('button',{staticClass:"btn btn-clean btn-sm btn-icon btn-icon-md",attrs:{"type":"button"},on:{"click":_vm.closeBot}},[_c('i',{staticClass:"ki ki-close icon-1x"})])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"scroll scroll-pull",attrs:{"data-height":_vm.size !== 'xl' ? '400' : '700',"data-mobile-height":"350"}},[_c('transition',{attrs:{"enter-active-class":"animate__animated animate__bounceInUp"}},[_c('div',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll",value:({
            always: true,
            smooth: true,
            scrollonremoved: true,
            smoothonremoved: true
          }),expression:"{\n            always: true,\n            smooth: true,\n            scrollonremoved: true,\n            smoothonremoved: true\n          }"}],staticClass:"messages overflow-x-auto"},[_vm._l((_vm.messages),function(message,index){return _c('div',{key:index,staticClass:"d-flex flex-column mb-5 align-items-start",class:{
              'align-items-start': message.type !== 'answer',
              'align-items-end': message.type === 'answer'
            }},[(message.type !== 'answer')?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 mr-3",staticStyle:{"font-size":"3rem"}},[_c('img',{attrs:{"width":"50","src":require(`@/assets/images/menu/bots/${_vm.bot.image}`)}})]),_c('div',[_c('a',{staticClass:"text-dark-75 text-hover-primary font-weight-bold font-size-h6",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.bot.title))]),_c('span',{staticClass:"text-muted font-size-sm"},[_vm._v(" "+_vm._s(_vm.last_activity(message.time))+" ")])])]):_vm._e(),(message.type !== 'answer')?_c('div',{class:'mt-2 rounded p-5 bg-light-primary font-weight-bold font-size-lg text-left ' +
                  _vm.lowerBotName},[_c('span',{staticClass:"message-content",domProps:{"innerHTML":_vm._s(message.title)}})]):_vm._e(),(message.type === 'answer')?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('span',{staticClass:"text-muted font-size-sm"},[_vm._v(" "+_vm._s(_vm.last_activity(message.time))+" ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary font-weight-bold font-size-h6",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t("menu.chatbot.you")))])]),_c('div',{staticClass:"symbol symbol-circle symbol-40 ml-3"},[_c('img',{attrs:{"alt":"Pic","src":require(`@/assets/images/avatars/${_vm.avatar}`)}})])]):_vm._e(),(message.type === 'answer')?_c('div',{staticClass:"mt-2 rounded py-3 px-5 bg-light-primary text-inverse-primary font-weight-bold font-size-lg text-right",staticStyle:{"background":"#777 repeat scroll 0% 0% !important"}},[_c('span',{staticClass:"message-content",domProps:{"innerHTML":_vm._s(message.title)}})]):_vm._e()])}),(_vm.loading)?_c('div',{staticClass:"d-flex flex-column mb-5 align-items-start"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 mr-3",staticStyle:{"font-size":"3rem"}},[_c('img',{attrs:{"width":"50","src":require(`@/assets/images/menu/bots/${_vm.bot.image}`)}})]),_c('div',[_c('a',{staticClass:"text-dark-75 text-hover-primary font-weight-bold font-size-h6",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.bot.title))])])]),_c('div',{class:'mt-2 rounded p-5 bg-light-primary font-weight-bold font-size-lg text-right ' +
                  _vm.lowerBotName},[_c('div',{staticClass:"waving"},[_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"})])])]):_vm._e()],2)])],1)]),_c('div',{staticClass:"card-footer align-items-center"},[(_vm.actualType === 'nlp')?_c('div',{staticClass:"d-flex align-items-center mt-5"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.textAnswer),expression:"textAnswer"}],staticClass:"form-control border-0 p-0",attrs:{"rows":"2","placeholder":"Type a message"},domProps:{"value":(_vm.textAnswer)},on:{"input":function($event){if($event.target.composing)return;_vm.textAnswer=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"mr-3"},[_c('button',{staticClass:"btn btn-clean btn-icon btn-md mr-1",on:{"click":function($event){return _vm.answer(_vm.textAnswer, true)}}},[_c('i',{staticClass:"flaticon2-send-1 icon-lg"})])])]):_c('div',{staticClass:"d-flex align-items-center justify-content-between mt-5"},[(!_vm.loading)?_c('div',_vm._l((_vm.choices),function(choice,index){return _c('b-button',{key:index,staticClass:"btn btn-secondary btn-md text-uppercase font-weight-bold chat-send py-2 px-6 mr-2 mb-2",attrs:{"type":"button"},domProps:{"innerHTML":_vm._s(choice.text)},on:{"click":function($event){return _vm.answer(choice, _vm.actualType === 'radionlp')}}})}),1):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }