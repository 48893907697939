<template>
  <div
    id="user"
    class="header-mobile-fixed d-flex flex-column"
    style="min-height: 100vh"
  >
    <div v-if="showMenu"><Menu v-if="!$route.meta.nomenu" /></div>
    <!--begin::Content-->
    <div
      class="content d-flex flex-column flex-column-fluid"
      id="kt_content"
      v-if="!$route.meta.fullscreen"
    >
      <!--begin::Entry-->
      <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container">
          <!--begin::View-->
          <router-view></router-view>
          <!-- end::View -->
        </div>
        <!-- end::Container -->
      </div>
      <!-- end::Entry -->
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
    <!-- end::Content -->
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/Menu.component";
import Footer from "@/components/Footer.component";

import { getConstants } from "@/api/misc.api";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "User",
  components: {
    Menu,
    Footer
  },
  data() {
    return {
      showMenu: this.$route.params.to === undefined
    };
  },
  computed: {
    ...mapGetters("Interactions", ["isInteractionsLoaded"])
  },
  methods: {
    ...mapActions("Interactions", ["loadInteractions"])
  },
  mounted() {
    document.body.classList.add("user-view");
    document.body.classList.remove("controller-view");

    if (!this.isInteractionsLoaded) {
      this.loadInteractions();
    }

    getConstants("SERVICETYPE_").then(response => {
      let services = response.data.records;
      this.$store.dispatch(
        "Auth/setServices",
        {
          services: services,
          userServices: JSON.parse(localStorage.getItem("user_services"))
        },
        { root: true }
      );
    });
  },
  watch: {
    isInteractionsLoaded(value) {
      if (!value) {
        this.loadInteractions();
      }
    }
  }
};
</script>

<style lang="scss" src="@/assets/scss/user/style.scss"></style>
<style lang="css" src="@/assets/scss/plugins/flaticon/flaticon.css"></style>
<style lang="css" src="@/assets/scss/plugins/flaticon2/flaticon.css"></style>
<style
  lang="css"
  src="@/assets/scss/plugins/keenthemes-icons/font/ki.css"
></style>

<style src="@/assets/scss/login.scss" lang="scss" scoped></style>

<style lang="scss">
.footer {
  margin-top: auto;
  width: 100%;
  display: table-row;
}
</style>
