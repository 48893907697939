<template>
  <!--begin::Chat Panel-->
  <!--begin::Card-->
  <div class="card card-custom" v-if="isChatbotActive">
    <!--begin::Header-->
    <div class="card-header align-items-center px-4 py-3">
      <div class="text-left flex-grow-1" />
      <div class="text-center flex-grow-1">
        <div class="text-dark-75 font-weight-bold font-size-h5">
          {{ bot.title }}
        </div>
        <div>
          <span
            class="label label-dot label-success"
            v-if="state === 'active'"
          ></span>
          <span
            class="label label-dot label-danger"
            v-if="state === 'offline'"
          ></span>
          <span class="font-weight-bold text-muted font-size-sm">
            &nbsp;{{ $t(`menu.chatbot.status.${state}`) }}
          </span>
        </div>
      </div>
      <div class="text-right flex-grow-1">
        <button
          type="button"
          class="btn btn-clean btn-sm btn-icon btn-icon-md"
          @click="$emit('expand-modal')"
        >
          <i class="fas fa-expand icon-1x"></i>
        </button>
        <button
          type="button"
          class="btn btn-clean btn-sm btn-icon btn-icon-md"
          @click="closeBot"
        >
          <i class="ki ki-close icon-1x"></i>
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Scroll-->
      <div
        class="scroll scroll-pull"
        :data-height="size !== 'xl' ? '400' : '700'"
        data-mobile-height="350"
      >
        <!--begin::Messages-->
        <transition enter-active-class="animate__animated animate__bounceInUp">
          <div
            class="messages overflow-x-auto"
            v-chat-scroll="{
              always: true,
              smooth: true,
              scrollonremoved: true,
              smoothonremoved: true
            }"
          >
            <!--begin::Message-->
            <div
              class="d-flex flex-column mb-5 align-items-start"
              v-for="(message, index) in messages"
              :key="index"
              :class="{
                'align-items-start': message.type !== 'answer',
                'align-items-end': message.type === 'answer'
              }"
            >
              <div
                class="d-flex align-items-center"
                v-if="message.type !== 'answer'"
              >
                <div class="symbol symbol-40 mr-3" style="font-size: 3rem">
                  <img
                    width="50"
                    :src="require(`@/assets/images/menu/bots/${bot.image}`)"
                  />
                </div>
                <div>
                  <a
                    href="#"
                    class="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
                    >{{ bot.title }}</a
                  >
                  <span class="text-muted font-size-sm">
                    {{ last_activity(message.time) }}
                  </span>
                </div>
              </div>
              <div
                :class="
                  'mt-2 rounded p-5 bg-light-primary font-weight-bold font-size-lg text-left ' +
                    lowerBotName
                "
                v-if="message.type !== 'answer'"
              >
                <span class="message-content" v-html="message.title" />
              </div>
              <div
                class="d-flex align-items-center"
                v-if="message.type === 'answer'"
              >
                <div>
                  <span class="text-muted font-size-sm">
                    {{ last_activity(message.time) }}
                  </span>
                  <a
                    href="#"
                    class="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
                    >{{ $t("menu.chatbot.you") }}</a
                  >
                </div>
                <div class="symbol symbol-circle symbol-40 ml-3">
                  <img
                    alt="Pic"
                    :src="require(`@/assets/images/avatars/${avatar}`)"
                  />
                </div>
              </div>
              <div
                class="mt-2 rounded py-3 px-5 bg-light-primary text-inverse-primary font-weight-bold font-size-lg text-right"
                style="background: #777 repeat scroll 0% 0% !important;"
                v-if="message.type === 'answer'"
              >
                <span class="message-content" v-html="message.title" />
              </div>
            </div>
            <div
              class="d-flex flex-column mb-5 align-items-start"
              v-if="loading"
            >
              <div class="d-flex align-items-center">
                <div class="symbol symbol-40 mr-3" style="font-size: 3rem">
                  <img
                    width="50"
                    :src="require(`@/assets/images/menu/bots/${bot.image}`)"
                  />
                </div>
                <div>
                  <a
                    href="#"
                    class="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
                    >{{ bot.title }}</a
                  >
                </div>
              </div>
              <div
                :class="
                  'mt-2 rounded p-5 bg-light-primary font-weight-bold font-size-lg text-right ' +
                    lowerBotName
                "
              >
                <div class="waving">
                  <div class="dot" />
                  <div class="dot" />
                  <div class="dot" />
                </div>
              </div>
            </div>
            <!--end::Message-->
          </div>
        </transition>
        <!--end::Messages-->
      </div>
      <!--end::Scroll-->
    </div>
    <!--end::Body-->
    <!--begin::Footer-->
    <div class="card-footer align-items-center">
      <!--begin::Compose-->

      <!-- CAJA OCULTA -->
      <div class="d-flex align-items-center mt-5" v-if="actualType === 'nlp'">
        <textarea
          class="form-control border-0 p-0"
          rows="2"
          v-model="textAnswer"
          placeholder="Type a message"
        />
        <div class="mr-3">
          <button
            class="btn btn-clean btn-icon btn-md mr-1"
            @click="answer(textAnswer, true)"
          >
            <i
              class="
            flaticon2-send-1 icon-lg"
            ></i>
          </button>
        </div>
      </div>

      <div
        class="d-flex align-items-center justify-content-between mt-5"
        v-else
      >
        <div v-if="!loading">
          <b-button
            type="button"
            class="btn btn-secondary btn-md text-uppercase font-weight-bold chat-send py-2 px-6 mr-2 mb-2"
            v-for="(choice, index) in choices"
            :key="index"
            v-html="choice.text"
            @click="answer(choice, actualType === 'radionlp')"
          />
        </div>
      </div>
      <!--begin::Compose-->
    </div>
    <!--end::Footer-->
  </div>
  <!--end::Card-->
  <!--end::Chat Panel-->
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import { saveInteraction, saveHelpInteraction } from "@/api/user/traits.api";
import store from "@/store";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "ChatBot",
  components: {},
  props: {
    size: {
      type: String,
      default: localStorage.getItem("chatSize") || "md"
    }
  },
  data() {
    return {
      loading: true,
      haveToReboot: false,
      state: "active",
      messages: [],
      choices: [],
      actualIndex: 0,
      actualType: "radio",
      answers: [],
      textAnswer: "",
      elements: {
        totalelements: 0,
        totalelementsdone: 0
      },
      showingSurveyEntityId: false,
      answersPackage: {},
      redirect: false
    };
  },
  computed: {
    ...mapGetters("Interactions", ["rawInteractions"]),
    ...mapGetters("User", ["avatar"]),
    ...mapGetters("Constants", ["getConstant"]),
    ...mapGetters("Chatbot", [
      "bot",
      "questions",
      "totalelements",
      "totalelementsdone",
      "survey",
      "isLoading",
      "isChatbotActive",
      "nextSurvey",
      "status"
    ]),
    lowerBotName() {
      return this.bot?.title.toLowerCase();
    },
    last_activity() {
      return date => {
        return moment(date).fromNow();
      };
    }
  },
  methods: {
    ...mapActions("Profile", {
      resetProfile: "reset"
    }),
    ...mapActions("User", {
      resetUser: "reset"
    }),
    ...mapActions("Interactions", {
      resetInteractions: "reset"
    }),
    ...mapActions("Constants", {
      resetConstants: "reset"
    }),
    ...mapActions("User", ["reset_bot"]),
    ...mapActions("Chatbot", [
      "activate",
      "resetStatus",
      "reset",
      "addQuestions",
      "callSurvey",
      "loadHelpersBots"
    ]),
    resetStates() {
      store.dispatch("Profile/reset");
      store.dispatch("Profile/reloadInteractions");
      store.dispatch("User/reset");
      store.dispatch("Interactions/reset");
      store.dispatch("User/reset_bot");
    },
    doAllResets(finished = false) {
      this.redirect =
        this.survey &&
        this.survey.surveyFamilyId !=
          this.getConstant("SURVEYTYPE_KYMATIO_WELCOME");
      if (finished) {
        if (
          this.showingSurveyEntityId !== false &&
          this.showingSurveyEntityId !== undefined
        ) {
          if (this.$route.name !== "TrainingDetail" && this.redirect) {
            this.$router.push({
              name: "TrainingDetail",
              params: {
                id: this.showingSurveyEntityId
              }
            });
          }
        }
      }
      this.reset();
      if (this.haveToReboot) {
        this.resetStates();
        this.loadHelpersBots();
      }
      this.$emit("close");
    },
    closeBot() {
      this.doAllResets();
      this.$emit("close");
    },
    next(time = 700) {
      this.loading = true;
      this.choices = [];
      setTimeout(() => {
        let question = this.questions
          ? this.questions[this.actualIndex]
          : false;
        if (question) question["time"] = moment();
        if (this.actualIndex < this.questions.length && question) {
          this.messages.push(question);
          this.choices = question.choices;
          this.actualType = question.type;
          this.actualIndex++;
          if (question.type === "info") {
            this.next(question.title.length * 10);
          } else {
            this.loading = false;
          }
        } else {
          this.loading = false;
        }
      }, time);
    },
    answer(option, text = false) {
      this.haveToReboot = true;
      this.showingSurveyEntityId = this.survey.surveyEntityId;
      this.answers.push({
        value: text ? option : option.value,
        title: text ? option : option.text
      });
      this.messages.push({
        type: "answer",
        value: text ? option : option.value,
        title: text ? option : option.text,
        time: new Date()
      });
      this.textAnswer = "";
      this.choices = [];
      if (option.value?.params?.redirect == true) {
        if (this.$route.name != option.value.params.section) {
          this.$router.push({ name: option.value.params.section });
        }
        this.reset();
      } else {
        this.loading = true;
        if (!text) {
          this.answersPackage[this.questions[this.actualIndex - 1].name] =
            option.value;
        } else {
          this.answersPackage[this.questions[this.actualIndex - 1].name] = {
            value: option,
            type: this.actualType === "nlp" ? "text" : "options"
          };
        }

        if (this.questions[this.actualIndex - 1].isRequired) {
          let toSend = {
            entityId: this.survey.entityId,
            entityType: this.survey.entityType,
            stakeholderCompanyId: localStorage.getItem("managedCompanyId"),
            surveyId: this.survey.surveyId,
            surveyEntityId: this.survey.surveyEntityId,
            surveyTypeId: this.survey.surveyTypeId,
            templateSurveyId: this.survey.templateSurveyId,
            totalelements: this.totalelements,
            totalelementsdone: this.elements.totalelementsdone,
            stakeholderId: localStorage.getItem("stakeholderId"),
            questions: this.answersPackage
          };
          if (this.survey.surveyEntityId) {
            saveInteraction(this.survey.surveyEntityId, toSend, true).then(
              res => {
                this.answersPackage = {};
                if (res.data.records.questions.length === 0) {
                  this.state = "offline";
                  if (!this.nextSurvey) {
                    this.doAllResets();
                  } else {
                    if (this.nextSurvey) {
                      this.activate(this.nextSurvey.surveyEntityId);
                    } else {
                      this.doAllResets();
                    }
                  }
                } else {
                  this.elements.totalelementsdone =
                    res.data.records.totalelementsdone;
                  this.addQuestions(res.data.records.questions).then(() => {
                    this.actualIndex = 0;
                    this.next(700);
                  });
                }
              }
            );
          } else {
            saveHelpInteraction(this.bot.surveyTypeId, toSend).then(res => {
              this.answersPackage = {};
              if (res.data.records.questions.length === 0) {
                this.state = "offline";
                if (!this.nextSurvey) {
                  this.doAllResets();
                } else {
                  if (this.nextSurvey) {
                    this.activate(this.nextSurvey.surveyEntityId);
                  } else {
                    this.doAllResets();
                  }
                }
              } else {
                this.elements.totalelementsdone =
                  res.data.records.totalelementsdone;
                if (_.isObject(_.first(res.data.records.questions).title)) {
                  if (_.first(res.data.records.questions).title.redirect) {
                    this.doAllResets();
                    this.$router.push({
                      name: _.first(res.data.records.questions).title.section
                    });
                  }
                } else {
                  this.addQuestions(res.data.records.questions).then(() => {
                    this.actualIndex = 0;
                    this.next(700);
                  });
                }
              }
            });
          }
        } else if (this.questions.length === this.actualIndex) {
          if (!this.nextSurvey) {
            this.doAllResets(true);
          } else {
            if (this.nextSurvey) {
              let survey = _.find(this.rawInteractions, {
                surveyEntityId: this.nextSurvey.surveyEntityId
              });
              this.callSurvey(survey);
            } else {
              this.doAllResets(true);
            }
          }
        } else {
          this.next(700);
        }
      }
    }
  },
  watch: {
    isLoading(value) {
      this.loading = value;
    },
    isChatbotActive(value) {
      if (!value) {
        this.messages = [];
        this.actualIndex = 0;
        this.answers = [];
        this.elements = {
          totalelements: 0,
          totalelementsdone: 0
        };
        this.answersPackage = {};
      } else {
        this.next();
      }
    }
  }
};
</script>

<style lang="scss">
.chatbot-xl .messages {
  height: 100%;
  overflow-x: auto;
}
.chatbot-md .messages {
  height: 400px;
  overflow-x: auto;
}
.messages .message-content img {
  height: 120px;
}
.scroll.scroll-pull {
  height: 100%;
}
.chatbot-xl {
  .card-body {
    display: flex;
    max-height: 100%;
    position: relative;
  }
  .scroll.scroll-pull {
    max-height: 90%;
    position: absolute;
    width: 95%;
  }
}
div.waving {
  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    background: white;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}
div.kybot .dot {
  background: #23422f;
}
div.cybot .dot {
  background: #823f57;
}
div.supbot .dot {
  background: #333;
}
div.wellbot .dot {
  background: #043c65;
}

.kybot.bg-light-primary {
  background-color: #b1f3de !important;
  color: #23422f;
}
.cybot.bg-light-primary {
  background-color: #f4e4f5 !important;
  color: #823f57;
}
.supbot.bg-light-primary {
  background-color: #e2e2e2 !important;
  color: #333 !important;
}
.wellbot.bg-light-primary {
  background-color: #dff3ff !important;
  color: #043c65 !important;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}
</style>
