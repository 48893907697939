<template>
  <div>
    <!--begin:Header-->
    <div
      class="d-flex flex-column flex-center py-10 bg-dark-o-5 rounded-top bg-light"
    >
      <h4 class="text-dark font-weight-bold">
        {{ $t("menu.bots.title") }}
      </h4>
    </div>
    <!--end:Header-->
    <!--begin:Nav-->
    <div class="row row-paddingless">
      <!--begin:Item-->
      <div class="col-6" v-for="(bot, index) in sectionBots" :key="bot.title">
        <a
          href="#"
          @click="callBot({ bot: bot })"
          style="min-height: 100%;"
          :class="
            'd-block py-10 px-5 text-center bg-hover-light ' +
              (index < 2 ? 'border-bottom' : '') +
              ' ' +
              (index % 2 ? '' : 'border-right')
          "
        >
          <span>
            <img
              width="100"
              :src="require(`@/assets/images/menu/bots/${bot.image}`)"
            />
          </span>
          <span
            class="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1"
            v-html="bot.title"
          />
          <span
            class="d-block text-dark-50 font-size-lg"
            v-html="bot.description"
          />
        </a>
      </div>
      <!--end:Item-->
    </div>
    <!--end:Nav-->
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Bots",
  computed: {
    ...mapGetters("Chatbot", ["bots", ""]),
    sectionBots() {
      return _.filter(this.bots, bot => bot.section == "menu");
    }
  },
  methods: {
    ...mapActions("Chatbot", ["callBot", "loadHelpersBots"])
  },
  mounted() {
    if (!this.areHelpersBotsLoaded) {
      this.loadHelpersBots();
    }
  }
};
</script>

<style lang="scss">
.menu-rounded .col-6:nth-child(3) .bg-hover-light {
  border-bottom-left-radius: 30px;
}
.menu-rounded .col-6:nth-child(4) .bg-hover-light {
  border-bottom-right-radius: 30px;
}
</style>
