<template
  ><!--begin::Footer-->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="container d-flex flex-column flex-md-row align-items-center justify-content-between"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted font-weight-bold mr-2"
          >{{ year }}© Kymatio. {{ $t("footer.reserved") }}</span
        >
        <a
          href="https://www.kymatio.com"
          target="_blank"
          class="text-dark-75 text-hover-primary"
          >www.kymatio.com</a
        >
      </div>
      <!--end::Copyright-->
      <!--begin::Nav-->
      <div class="nav nav-dark order-1 order-md-2">
        <a
          href="javascript:void(0)"
          @click="$bvModal.show('modal-privacy')"
          class="nav-link pr-3 pl-0"
          >{{ $t("footer.privacy") }}</a
        >
        <a
          href="javascript:void(0)"
          @click="$bvModal.show('modal-terms')"
          class="nav-link px-3"
          >{{ $t("footer.termsOfUse") }}</a
        >
      </div>
      <!--end::Nav-->
    </div>
    <!--end::Container-->
    <b-modal
      id="modal-privacy"
      hide-footer
      size="xl"
      scrollable
      :title="$t('legal.privacy.title')"
    >
      <div
        class="d-block mb-8 pt-1 pb-4 pr-8 pl-8"
        v-for="(content, index) in $t('legal.privacy.contents')"
        :key="content.title"
      >
        <h3>{{ index + 1 }}- {{ content.title }}</h3>
        <p v-html="content.content"></p>
        <span
          v-for="(child, subindex) in content.children"
          v-bind:key="child.title"
        >
          <h6>
            {{ index + 1 }}.{{ subindex + 1 }} - <span v-html="child.title" />
          </h6>
          <span v-html="child.content" />
        </span>
      </div>
    </b-modal>
    <b-modal
      id="modal-terms"
      hide-footer
      size="xl"
      scrollable
      :title="$t('legal.terms.title')"
    >
      <div
        class="d-block mb-8 pt-1 pb-4 pr-8 pl-8"
        v-for="(content, index) in $t('legal.terms.contents')"
        :key="content.title"
      >
        <h3>{{ index + 1 }}- {{ content.title }}</h3>
        <p v-html="content.content"></p>
        <span
          v-for="(child, subindex) in content.children"
          v-bind:key="child.title"
        >
          <h6>
            {{ index + 1 }}.{{ subindex + 1 }} - <span v-html="child.title" />
          </h6>
          <span v-html="child.content" />
        </span>
      </div>
    </b-modal>
  </div>
  <!--end::Footer-->
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "FooterComponent",
  computed: {
    year() {
      return moment(new Date()).format("Y");
    }
  }
};
</script>

<style lang="scss">
#modal-privacy i,
#modal-terms i {
  font-size: 1rem;
}
</style>
